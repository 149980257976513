import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import './navBar.css';
import { backendURLContext } from '../index.js'
// import {callBackEndGetRate} from '../Easyship/Easyship.js'

//  Images ///
import logoSmallWhite from "../images/logoFinal_small_white.png";


function NavBar({cartCount, animateModal, textDataArray, displayLang, setDisplayLang, setDisplayLangLong,
  setDisplayCurr, displayCurr, displayLangLong, resetShippingOptions, setUpdateShippingOptions, navigate}){
    const [position, setPosition] = useState(0);
    const [carouselHeight, setCarouselHeight] = useState(0);
    const [hideCurrSelector, setHideCurrSelector] = useState(true);
    const urls = useContext(backendURLContext);
    const backendURL = urls.backend;
    const frontendURL = urls.frontend;
    const tt = textDataArray._navBar;
    let location = useLocation();

    useEffect(()=>{    
      console.log("dans useEffet du nav bar hide curr, pathname: " + location.pathname + "target url: " + frontendURL+"/checkout" );
      window.location.href == frontendURL+"/checkout"? setHideCurrSelector(true):setHideCurrSelector(false)
    },[location.pathname])


    const showHide=()=>{
      console.log("dans Show hide");
      let target = document.querySelector(".SNBContainer");
      if (target.classList.contains("open")){
          //close
          target.classList.remove("open");
      } else {
          target.classList.add("open");
      }
  }

  const changeLang=(shortName, longName)=>{
    setDisplayLang(shortName);
    setDisplayLangLong(longName);
    localStorage.setItem('selectedLang', shortName);
  };

  const changeCurr=(newVal)=>{
    console.log("dans changeCurr, newval: " + newVal + "location: " + window.location.href);
    setDisplayCurr(newVal);
    localStorage.setItem('selectedCurr', newVal);
    
    if(window.location.href == frontendURL+"/cart"){
      resetShippingOptions();
      setUpdateShippingOptions(true);
    }

  };

  const testEmail=()=>{
    fetch(backendURL+"/cart/test")
  }

  // Enable navigate function for all links on the page
  document.querySelectorAll('a').forEach(link => link.addEventListener('click', navigate));

    return(
      <nav id="navBar">
        <div id='navLogoContainer' className='navImgContainer'>
              <NavLink to="/" id="navLogo" className='navLink'>HNK
              </NavLink>
            {/* <img src={logoSmallWhite} alt="HNK"/> */}
        </div>
        <div className='NB_LinkContainer'>
            <NavLink className='navLink' activeClassName='activeNavLink' to="/aboutus">{tt.aboutUs[displayLang]}</NavLink>
            <div className='DDContainer'>
              <NavLink className='navLink' activeClassName='activeNavLink' to="/instock">{tt.products[displayLang]}</NavLink>
              <div className='DD'>
                <NavLink className='navLink' activeClassName='activeNavLink' to="/instock">{tt.pMenuIS[displayLang]}</NavLink>
                <NavLink className='navLink' activeClassName='activeNavLink' to="/buildyourown">{tt.pMenuBYO[displayLang]}</NavLink>
                <NavLink className='navLink' id="CommponentsDDContainer" activeClassName='activeNavLink' to="/components">
                {tt.pMenuComp[displayLang]}
                  <div className='subDD'>
                      <NavLink className='navLink' activeClassName='activeNavLink' to="/components/wood">{tt.subPWE[displayLang]}</NavLink>
                      <NavLink className='navLink' activeClassName='activeNavLink' to="/components/pcb">{tt.subPPCB[displayLang]}</NavLink>
                      <NavLink className='navLink' activeClassName='activeNavLink' to="/components/plate">{tt.subPPlate[displayLang]}</NavLink>
                      <NavLink className='navLink' activeClassName='activeNavLink' to="/components/switches">{tt.subPSwitches[displayLang]}</NavLink>
                      <NavLink className='navLink' activeClassName='activeNavLink' to="/components/keycaps">{tt.subPKeycaps[displayLang]}</NavLink>
                      <NavLink className='navLink' activeClassName='activeNavLink' to="/components/stabs">{tt.subPStabs[displayLang]}</NavLink>
                      <NavLink className='navLink' activeClassName='activeNavLink' to="/components/cable">{tt.subPCables[displayLang]}</NavLink>
                  </div>
                </NavLink>
                <NavLink className='navLink' activeClassName='activeNavLink' to="/ourCreations">{tt.pMenuOurCreations[displayLang]}</NavLink>
              </div>
            </div>
            <NavLink className='navLink' activeClassName='activeNavLink' to="/ownerspage">{tt.ownersPage[displayLang]}</NavLink>
        </div>
        {/* <div onClick={(e)=>{testEmail("cart")}}>Test email</div> */}
        <div className='NB_LinkContainer showOnMobile'>
            <div className='navImgContainer' id='navCartLogo'>
              <NavLink className='navLink' activeClassName='activeNavLink' to="/cart" id='cartLogo'> 
                {/* <img src={require('../images/Icon_Cart_C2_6701532.png')} alt="Cart"/> */}
                cart
              </NavLink>
              <div className='navCartCount'>{cartCount}</div>
            </div>
            {hideCurrSelector?
                <div className='DDContainerOff'>
                  <div className='navLink'>{displayLang}-{displayCurr} </div>
                </div>
              :
              <div className='DDContainer'>
                <div className='navLink'>{displayLang}-{textDataArray._displayCurr.currSign[displayCurr]}
                  <div className='DD languageDD'>
                    <div className='navLink' id='langSubDDHover'>{displayLangLong}
                      <div className='subDD langCurrSubDD'>
                          <div onClick={(e)=>{changeLang("ENG", "English")}}>English</div>
                          <div onClick={(e)=>{changeLang("FR", "Français")}}>Français</div>
                          {/* <div onClick={(e)=>{changeLang("ES", "Español")}}>espa&ntilde;ol</div> */}
                      </div>
                    </div>
                    <div className='navLink' id='currSubDDHover'>{displayCurr}
                      <div className='subDD langCurrSubDD'>
                          <div onClick={(e)=>{changeCurr("USD")}}> {textDataArray._displayCurr.currSign.USD}</div>
                          <div onClick={(e)=>{changeCurr("CAD")}}> {textDataArray._displayCurr.currSign.CAD}</div>
                          <div onClick={(e)=>{changeCurr("EUR")}}> {textDataArray._displayCurr.currSign.EUR} EUR</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
            
        </div>

        <label for="hamburgerMenu">
            <input type="checkbox" id="hamburgerMenu" onClick={(e)=>{showHide()}}/> 
                <span></span>
                <span></span>
                <span></span>
        </label>
      </nav>
    )
  }


  export {NavBar}